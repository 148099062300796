/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum CustomerTypeEnum {
    APO = 'APO',
    API = 'API',
    CP = 'CP',
    DP = 'DP',
    GENERAL = 'GENERAL',
    LABO = 'LABO',
}