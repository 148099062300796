import {CustomerRestDto} from "@/api/pharma-customer/models";
import {CustomerTypeEnum} from "@/api/enums/customer-type-enum";

export class CustomerRestDtoModel implements CustomerRestDto {
    name: string;
    customer_type_code: CustomerTypeEnum;
    account_number?: number;
    customer_number?: number;
    website?: string | null;

    constructor(name: string, customer_type_code: CustomerTypeEnum) {
        this.name = name;
        this.customer_type_code = customer_type_code;
    }

    public static createWithDefaults(): CustomerRestDtoModel {
        return new CustomerRestDtoModel('', CustomerTypeEnum.APO);
    }
}